const ENVIRONMENT_CONFIG = {
  domains: {
    development: ["localhost", "zyla.in"],
    production: ["zyla.in"],
    staging: ["zyla.in"],
  },
  vars: {
    // "dev1.bridge.zyla.in","dev2.bridge.zyla.in","feature.bridge.zyla.in","bridge.zyla.in","bridge.prod.zyla.in","sandbox.bridge.zyla.in"
    development: {
      apiUrl: "https://dev1.api.zyla.in",
      domain: "localhost",
      serviceUrl: "https://services.dev1.zyla.in",
      serviceSocket: "wss://services.dev1.zyla.in",
      serviceNew: "https://services.dev1.zyla.in",
      localService: "https://services.dev1.zyla.in",
      // localService: "http://localhost:8080",
    },
    production: {
      apiUrl: "//api.zyla.in",
      staticUrl: "//api.zyla.in",
      domain: "bridge.zyla.in",
      serviceUrl: "//services.prod.zyla.in",
      serviceSocket: "wss://services.zyla.in",
      serviceNew: "//services.prod.zyla.in",
      localService: "//services.prod.zyla.in",
    },
    staging: {
      apiUrl: "https://dev1.api.zyla.in",
      domain: "dev1.bridge.zyla.in",
      serviceUrl: "https://services.dev1.zyla.in",
      serviceSocket: "wss://services.dev1.zyla.in",
      serviceNew: "https://services.dev1.zyla.in",
      localService: "https://services.dev1.zyla.in",
    },
  },
  environments: {
    development: "development",
    production: "production",
    staging: "staging",
  },
};

export default ENVIRONMENT_CONFIG;
